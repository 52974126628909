import React from "react";
import {Link} from "gatsby";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

import Layout from "../components/Layout/layout";
import Menu from "../components/Layout/Menu/Menu";
import Footer from "../components/Layout/Footer/Footer";
import Question from "../components/Layout/Page/Question";

import "./404.scss";

import notFound from "../images/not-found.jpg";

export default function NotFoundPage() {
    return (
        <Layout>
            <GatsbySeo
                title='Nie znaleziono strony'
                description='Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
                canonical='https://serwis-olaszewski.pl/404.html'
                openGraph={{
                    title: 'Nie znaleziono strony | Auto Serwis Olaszewski',
                    url: 'https://serwis-olaszewski.pl/404.html',
                    description: 'Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
                }}
                twitter={{
                    title: 'Nie znaleziono strony | Auto Serwis Olaszewski',
                    description: 'Specjalizujemy się we wszystkich markach samochodów. Działamy na terenie Trzebini.'
                }}
                noindex={true}
            />

            <Menu/>

            <main className="has-margin-top not-found-page">
                <section className="mt-5 mb-5">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column has-text-centered">
                                <p className="error-title">404</p>
                                <p className="error-message">Strona nie została znaleziona</p>
                            </div>
                            <div className="column">
                                <img src={notFound} alt="Nie znaleziono strony"/>
                            </div>
                        </div>
                    </div>
                </section>

                <Question title="Strona której szukasz nie istnieje.">
                    Zapraszamy do zapoznania się z <Link to="/zakres-uslug" className="has-text-dark">naszą oferą</Link>.
                </Question>
            </main>

            <Footer/>
        </Layout>
    );
};
